<template>
  <div class="osk-o-community-mark">
    <div class="font_11 italic">
      Only for
    </div>

    <div class="font_13 text-bold">
      O!Community
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "~/assets/scss/settings/index";

.osk-o-community-mark {
  max-width: 100%;
  font-weight: 600;
  text-align: left;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.font_11 {
  font-family: "Playfair Display", "Times New Roman", serif;
  font-size: 11px;
}

.font_13 {
  font-family: 'SF Pro', 'SF Pro Text', sans-serif;
  font-size: 13px;
}
</style>
