<template>
  <div :class="['osk-yandex-plus-badge', `osk-yandex-plus-badge--size-${size}`]">
    <div class="osk-yandex-plus-badge__price-box text-white">
      <div class="osk-yandex-plus-badge__icon-box">
        <UikitIcon
          name="YandexPlusCashback"
          color="white"
        />
      </div>
      {{ numberFormat(plus.points) }}
    </div>

    <div>баллов Плюса</div>
  </div>
</template>

<script lang="ts" setup>
import { YandexPlusInfo } from '~/restAPI/data-contracts';

const props = withDefaults(defineProps<{
  plus: YandexPlusInfo,
  size?: 's' | 'm'
}>(), {
  size: 's',
});

const { numberFormat } = useNumberFormatter();

</script>

<style scoped lang="scss">
@import "~/assets/scss/settings/index";

.osk-yandex-plus-badge {
  display: flex;
  align-items: center;
  @include font-style(12px, 10px, 500);

  &__price-box {
    display: flex;
    align-items: center;
    border-radius: 9px 2px 2px 9px;
    padding-right: 4px;
    margin-right: 4px;
    background: linear-gradient(90deg, #FF5C4D 0%, #EB469F 40%, #8341EF 100%);
  }

  &__icon-box {
    display: flex;
    height: 14px;
    width: 14px;
    align-items: center;
    justify-content: center;
  }

  &--size-m {
    .osk-yandex-plus-badge__icon-box {
      height: 18px;
      width: 18px;
    }
  }

  &--size-s {
    .osk-icon {
      width: 10px;
      height: 10px;
    }
  }

  &--size-m {
    @include font-style(16px, 14px, 500);

    .osk-icon {
      width: 14px;
      height: 14px;
    }
  }
}
</style>
