<template>
  <UikitForm
    class="osk-product-card-add"
    @submit-validate-success="addCart"
  >
    <UikitFormSelect
      v-model="selectSizeId"
      name="productCardAddSize"
      class="osk-product-card-add__select"
      :placeholder="selectTitle"
      :disabled="disabled"
      max-height="105px"
      :required="!disabled"
      no-error-message
    >
      <UikitFormSelectOption
        v-for="item in selectValues"
        :key="item.id"
        :label="item.name"
        :value="item.id"
      />
    </UikitFormSelect>

    <UikitButton
      color="secondary"
      class="osk-product-card-add__send"
      :loading="loading"
    >
      {{ $t('uikit.product.productCardAdd.addToCart') }}
    </UikitButton>
  </UikitForm>
</template>

<script setup lang="ts">
import { SizeValueDTOReq, ProductDTORes, PrimaryPageProductDTO } from '~/restAPI/data-contracts';
import { useProductService } from '~/services/product';
import { isCustomHTTPError } from '@/restAPI/error';
import { AnalyticsEvents } from '~/types/analytics';

import type { TSubmitSuccess } from '~/types/components/form';

const props = defineProps<{
  sizes?: SizeValueDTOReq[]
  productId?: number
  product?: ProductDTORes | PrimaryPageProductDTO
  chapter?: string
}>();

const { t } = useI18n();
const loading = ref(false);

const { updateCart } = useCartStore();
const { addProductToCart } = useProductService();
const { $addEvent } = useNuxtApp();

const selectTitle = computed(() => {
  if (!props.sizes) {
    return t('uikit.product.productCardAdd.withoutSize');
  }
  if (props.sizes.length === 1) {
    return getTitle(props.sizes[0]);
  }

  return t('uikit.product.productCardAdd.selectSize');
});
const disabled = computed(() => !props.sizes || props.sizes.length === 1);
const selectValues = computed(() => props.sizes?.map((size) => ({
  id: size.id!,
  name: getTitle(size),
})) ?? []);

const selectSizeId = ref(disabled.value ? props.sizes?.[0].id : undefined);

function getTitle(size: SizeValueDTOReq) {
  return `${size.productSizeType !== 'NO_SIZE' ? size.productSizeType : ''} ${size.productSizeValue ?? ''}`;
}

async function addCart({ values }: TSubmitSuccess) {
  if ((!disabled.value && !values.productCardAddSize) || !props.productId) {
    return;
  }

  loading.value = true;

  try {
    const sizeId = values.productCardAddSize ?? props.sizes?.[0].id;

    const data = await addProductToCart({ productId: props.productId, sizeId });

    updateCart(data);

    $addEvent(AnalyticsEvents.AddToCart, {
      product_id: props.productId,
      chapter: props.chapter || undefined,
      product: props.product,
    });
  } catch (error) {
    if (isCustomHTTPError(error) && error.response.data?.humanMessage) {
      // eslint-disable-next-line no-alert
      alert(error.response.data.humanMessage);
    }
    console.error(error);
  } finally {
    loading.value = false;
  }
}
</script>

<style scoped lang="scss">
@import "~/assets/scss/settings/index";

.osk-product-card-add {
  width: 100%;
  margin-top: $indent-medium;
  display: flex;
  flex-direction: column;

  &__send {
    margin-top: $indent-compact;
  }

  &__select {
    :deep(.osk-field__inner) {
      border: 1px solid $black;
    }
  }
}
</style>
