<template>
  <div :class="['osk-yandex-split-badge', `osk-yandex-split-badge--size-${size}`]">
    <div class="osk-yandex-split-badge__price-box bg-fill-light-default-900 text-white">
      <!--      <UikitIcon
        name="YandexSplitPacman"
        color="white"
      />-->
      <img
        v-if="size === 's'"
        src="~/assets/icons/YandexSplitPacman14.svg"
        width="14"
        height="14"
      >
      <img
        v-if="size === 'm'"
        src="~/assets/icons/YandexSplitPacman18.svg"
        width="18"
        height="18"
      >
      {{ numberToCurrency(split.firstPayment, false, 0) }}
    </div>

    <div>{{ text }}</div>
  </div>
</template>

<script lang="ts" setup>
import { SplitInfo } from '~/restAPI/data-contracts';

const props = withDefaults(defineProps<{
  split: SplitInfo,
  showPartsInfo?: boolean,
  currency?: string,
  size?: 's' | 'm'
}>(), {
  currency: 'RUB',
  size: 's',
});

const { numberToCurrency } = useNumberFormatter();

const text = computed(() => (props.showPartsInfo ? `× ${props.split.parts.length} платежа в Сплит` : 'в Сплит'));
</script>

<style scoped lang="scss">
@import "~/assets/scss/settings/index";

.osk-yandex-split-badge {
  display: flex;
  align-items: center;
  @include font-style(12px, 10px, 500);

  &__price-box {
    display: flex;
    align-items: center;
    border-radius: 9px 2px 2px 9px;
    padding-right: 4px;
    margin-right: 4px;
  }

  .osk-icon {
    margin-right: 2px;
  }

  &--size-s {
    .osk-icon {
      width: 14px;
      height: 14px;
    }
  }

  &--size-m {
    @include font-style(16px, 14px, 500);

    .osk-icon {
      width: 18px;
      height: 18px;
    }
  }
}
</style>
