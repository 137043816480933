<template>
  <div
    class="osk-product-card"
    :class="{
      'osk-product-card_small': small,
    }"
  >
    <UikitUser
      v-if="!isSellerHidden && productComp.seller"
      class="osk-product-card__seller"
      :user="productComp.seller"
      @user-click="handleUserClick"
    />

    <div
      :class="{
        'osk-product-card__card': true,
        'osk-product-card__card_centered': centered,
      }"
    >
      <UikitIcon
        v-if="!showLabel && productComp.conditionId === 1"
        name="Tag"
        size="l"
        class="osk-product-card__card-icon"
      />

      <div class="osk-product-card__card-top">
        <UikitUserLikesCount
          v-if="productComp.id"
          :likes-count="productComp.likeCount"
          :is-liked="isLiked"
          :product-id="productComp.id"
          :chapter="chapter"
          :chapter-id="chapterId"
          :analytics="{
            ...analytics,
            value: getCommunity(product),
          }"
          @like="isLiked = true"
          @dislike="isLiked = false"
        />
      </div>

      <nuxt-link
        :to="productComp.url"
        :class="{
          'osk-product-card__card-img': true,
          'osk-product-card__card-img_small': smallImage,
        }"
        @click="handleProductClick"
      >
        <picture v-if="productComp.imgPath">
          <source
            :srcset="getImgPath(productComp.imgPath)"
            type="image/webp"
          >
          <img
            :class="{
              'osk-product-card__card-img-pic': true,
              'osk-product-card__card-img-pic_small': smallImage,
            }"
            :src="productComp.imgPath"
            :alt="`${productComp.brandName} ${productComp.name}`"
            :loading="imgLoadLazy ? 'lazy' : undefined"
            @error="onImgError"
          >
        </picture>

        <div
          v-if="productComp.exclusiveSelectionTime"
          class="osk-product-card__o-community-product-mark"
        >
          <ProductOCommunityProductMark />
        </div>
      </nuxt-link>

      <UikitLabel
        v-if="showLabel && productComp.conditionName"
        :text="productComp.conditionName"
        :icon="productComp.conditionId === 1 ? 'TagFilled' : undefined"
        class="osk-product-card__card-label"
      />

      <div class="osk-product-card__content">
        <div class="osk-product-card__card-info">
          <nuxt-link
            :to="productComp.url"
            class="osk-product-card__card-info-brand"
            @click="handleProductClick"
          >
            {{ productComp.brandName }}
          </nuxt-link>

          <div class="osk-product-card__card-info-name">
            {{ productComp.name }}
          </div>

          <template v-if="!hideSize">
            <div
              v-if="(productComp.sizeType && productComp.sizes && productComp.sizeType !== 'NO_SIZE' && !canAddToCart)"
              class="osk-product-card__card-info-size"
            >
              <template v-if="['AGE', 'HEIGHT'].includes(productComp.sizeType)">
                {{ t('uikit.product.productCard.categorySizeValue', {categorySizeValue: productComp.sizes[0]?.categorySizeValue}) }}
              </template>

              <template v-else-if="productComp.formattedSize">
                {{ productComp.formattedSize }}
              </template>

              <template v-else-if="productComp.sizes">
                {{ productComp.sizeType }} {{ getProductSizes(productComp.sizes) }}
              </template>
            </div>

            <div
              v-else-if="productComp.sizeType === 'NO_SIZE'"
              class="osk-product-card__card-info-size"
            >
              One size
            </div>

            <div
              v-else
              class="osk-product-card__card-info-size"
            >
              &nbsp;
            </div>
          </template>
        </div>

        <div
          v-if="!hideSize && product.split"
          class="osk-product-card__yandex-split"
        >
          <YandexSplitBadge
            :split="product.split"
          />
        </div>

        <div
          v-if="!hideSize && product.yandexPlus"
          class="osk-product-card__yandex-plus"
        >
          <YandexPlusBadge
            v-if="product.yandexPlus"
            :plus="product.yandexPlus"
          />
        </div>

        <div class="osk-product-card__card-info-price">
          <span class="osk-product-card__card-info-price-content">
            <span
              v-if="!hideSize && productOldPriceComp"
              class="osk-product-card__card-info-price-discount"
            >
              {{ numberToCurrency(productOldPriceComp) }}
            </span>

            <span
              v-if="productComp.price"
              class="osk-product-card__card-info-price-value"
            >
              {{ numberToCurrency(productComp.price) }}
            </span>
          </span>

          <span
            v-if="!hideSize && productComp.discount"
            class="osk-product-card__card-info-price-discount-value"
          >
            -{{ productComp.discount }}%
          </span>
        </div>
      </div>

      <UikitProductCardAdd
        v-if="canAddToCart"
        :product-id="productComp.id"
        :product="product"
        :sizes="productComp.sizes"
        :chapter="chapter"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ProductDTORes, SizeValueDTOReq, PrimaryPageProductDTO } from '~/restAPI/data-contracts';
import { AnalyticsEvents, IEventOptions } from '~/types/analytics';
import { getCommunity } from '~/services/analytics/oskellyAnalytics';

interface IOptions {
  chapter?: string
  id_chapter?: string
  item?: string
  chapter_index?: number
  segment_id?: number
  index?: number
  searchId?: string | null
}

const props = defineProps<{
  product: ProductDTORes | PrimaryPageProductDTO
  addToCart?: boolean
  imgLoadLazy?: boolean
  showLabel?: boolean
  smallImage?: boolean
  chapter?: string
  chapterId?: string
  index?: number
  eventOptions?: IEventOptions,
  useSwiperAnalytics?: boolean,
  isSellerHidden?: boolean,
  small?: boolean
  hideSize?: boolean
  centered?: boolean
  analytics?: IOptions
}>();

const emits = defineEmits<{
  (e: 'productClick', value: { productId?: number, index?: number, value?: string }): void,
}>();

const { $addEvent } = useNuxtApp();
const { t } = useI18n();

const { onImgError, getImgPath } = useImgCdnPath();
const { numberToCurrency } = useNumberFormatter();

const isLiked = ref(props.product.isLiked);

const productComp = computed(() => {
  const { product } = props;

  const isProductDTORes = checkProductDTORes(product);

  return {
    ...product,
    id: isProductDTORes ? product.productId : product.id,
    url: isProductDTORes ? product.url : `/products/${product.id}`,
    likeCount: isProductDTORes ? product.likesCount : product.likeCount,
    imgPath: isProductDTORes ? product.primaryImageUrl : product.imgPath,
    brandName: isProductDTORes ? product.brand?.name : product.brandName,
    formattedSize: isProductDTORes ? null : product.formattedSize,
  };
});

const productOldPriceComp = computed(() => {
  const { product } = props;
  const isProductDTORes = checkProductDTORes(product);

  if (!isProductDTORes) return product.oldPrice;

  const {
    price, rrpPrice, startPrice, higherPrice,
  } = product;

  if (higherPrice) return higherPrice;

  if (price && startPrice && startPrice > price) {
    return startPrice;
  }

  if (price && rrpPrice && rrpPrice > price) {
    return rrpPrice;
  }

  return 0;
});

const canAddToCart = computed(() => props.addToCart && productComp.value.id);

function checkProductDTORes(product: ProductDTORes | PrimaryPageProductDTO): product is ProductDTORes {
  return !!(product as ProductDTORes).productId;
}

function getProductSizes(sizes: SizeValueDTOReq[]) {
  return sizes && sizes.length ? sizes.map((size) => size.productSizeValue).join('/') : '';
}

function handleUserClick(userId: number) {
  $addEvent(AnalyticsEvents.UserClick, {
    seller_id: userId,
    chapter: props.analytics?.chapter || props.chapter,
    chapter_index: props.analytics?.chapter_index,
    segment_id: props.analytics?.segment_id,
    id_chapter: props.analytics?.id_chapter || props.chapterId,
    item: 'product',
    action: 'seller',
    value: getCommunity(props.product),
  });
}

function handleProductClick() {
  if (props.useSwiperAnalytics) {
    emits('productClick', { productId: productComp.value.id, index: props.index, value: getCommunity(props.product) });
    return;
  }

  const options: any = {
    product_id: productComp.value.id,
    id_item: props.analytics?.searchId ?? String(productComp.value.id),
    chapter: props.chapter,
    id_chapter: props.chapterId,
    index: props.index,
    ...(props.eventOptions?.productClick ?? []),
    value: getCommunity(props.product),
  };

  if (props.analytics?.searchId) {
    options.page = 'search';
  }

  $addEvent(AnalyticsEvents.ProductClick, options);
}
</script>

<style scoped lang="scss">
@import "~/assets/scss/settings/index";

.osk-product-card {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__seller {
    margin-bottom: $indent-compact;
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    border-radius: $radius-small;
    position: relative;
  }

  &__card-top {
    position: absolute;
    top: 18.5px;
    right: 17.5px;
    z-index: 1;
  }

  &__card-icon {
    position: absolute;
    top: 16px;
    left: 16px;
    z-index: 1;
  }

  &__card-img {
    margin-bottom: $indent-compact;
    width: 100%;
    @include image-bg-grey;
    height: 280px;

    @include media-query(lg-and-up) {
      height: 282px;
    }

    &_small {
      height: 208px;

      @include media-query(lg-and-up) {
        height: 282px;
      }
    }
  }

  &__card-img-pic {
    display: block;
    width: 100%;
    object-fit: contain;
    object-position: center;
    height: 228px;
    margin-top: 40px;

    @include media-query(lg-and-up) {
      height: 230px;
    }

    &_small {
      height: 158px;

      @include media-query(lg-and-up) {
        height: 230px;
      }
    }
  }

  &__o-community-product-mark {
    position: absolute;
    left: 0;
    bottom: 0;
    margin: 0 12px 12px;

    @include media-query(md-and-up) {
      margin: 0 16px 16px;
    }
  }

  &__card-label {
    margin-bottom: $indent-compact;
  }

  &__card-info {
    margin-bottom: $indent-compact;
    width: 100%;
    padding-top: $indent-xsmall;
  }

  &__card-info-brand {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    font-weight: $font-weight-bold;
    font-size: $font-size-subheading;
    line-height: normal;
    margin-bottom: $indent-small;
  }

  &__card-info-name,
  &__card-info-size {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__card-info-name {
    margin-bottom: $indent-compact;
  }

  &__card-info-size {
    color: $grey;
  }

  &__card-info-price {
    display: flex;
    align-items: baseline;
    width: 100%;
    margin-top: auto;
  }

  &__card-info-price-content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: flex-end;

    @include media-query(lg-and-up) {
      flex-direction: row-reverse;
    }
  }

  &__card-info-price-value {
    @include font-style($font-size-body, $line-height-body, $font-weight-bold);
    margin-right: $indent-small;
  }

  &__card-info-price-discount {
    @include font-style($font-size-caption, $line-height-caption, $font-weight-medium);
    color: $grey;
    text-decoration: line-through;
    margin-bottom: 2px;

    @include media-query(lg-and-up) {
      margin-bottom: 0;
    }
  }

  &__card-info-price-discount-value {
    margin-top: auto;

    @include media-query(lg-and-up) {
      margin-left: $indent-small;
    }
  }

  &__content {
    max-width: 100%;
  }

  &__yandex-plus,
  &__yandex-split {
    height: 14px;
    margin-top: 8px;
  }

  &__yandex-plus {
    margin-bottom: 8px;
  }

  :deep(.osk-user-avatar) {
    width: 32px;
    height: 32px;
    margin-right: $indent-compact;
  }

  :deep(.osk-user__name) {
    font-size: $font-size-body;
    line-height: $line-height-body;
  }

  &_small {
    height: auto;

    & + & {
      margin-top: 24px;
    }

    .osk-product-card__card {
      flex-direction: row;
    }

    .osk-product-card__card-img {
      width: 164px;
      height: 164px;
      margin-bottom: 0;
      margin-right: 24px;
      flex-shrink: 0;
    }

    .osk-product-card__card-img-pic {
      height: 100%;
      width: 100%;
      margin-top: 0;
    }

    .osk-product-card__card-top {
      top: 0;
      right: 0;
    }

    .osk-product-card__card-icon[data-v-4922379b] {
      top: 12px;
      left: 12px;
      width: 20px;
      height: 20px;
    }

    .osk-product-card__content {
      width: 50%;
    }

    .osk-product-card__card-info {
      padding-top: 0;
    }
  }

  &__card_centered {
    text-align: center;

    .osk-product-card__card-info-price {
      justify-content: center;
    }
  }
}
</style>
